<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form ref="formRef" @finish="handleSubmit" :rules="formRules" :model="formState" layout="vertical">
        <img :style="{ width: '210px' }" :src="require('@/assets/img/logo.png')" alt="" />
        <sdHeading as="h3">Forgot Password?</sdHeading>
        <p class="forgot-text">
          Enter the email address you used when you joined and we’ll send you instructions to reset your password.
        </p>
        <div style="text-align: left;">
          <a-form-item label="Email Address" name="email">
            <a-input type="email" v-model:value="formState.email" placeholder="name@example.com" />
          </a-form-item>
        </div>
        <a-form-item>
          <sdButton class="btn-reset" htmlType="submit" type="primary" size="large">
            Send Reset Instructions
          </sdButton>
        </a-form-item>
        <p class="return-text">Return to <router-link to="/portal/login">Sign In</router-link></p>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { AuthWrapper } from './style';
import { API } from "@/services/api";
import Notification from "@/services/Notification";

export default {
  name: 'ForgotPassword',
  components: { AuthWrapper },
  computed: {
    formRef: function() {
      return this.$refs.formRef;
    }
  },
  data() {
    return {
      formState: {
        email: '',
      },
      formRules: {
        email: [{
          required: true, trigger: 'blur',
          message: 'Please input email',
        }, {
          type: 'email', trigger: 'blur',
          message: 'Please input valid email',
        }],
      }
    }
  },
  methods: {
    async handleSubmit() {
      const { replace, currentRoute } = this.$router;
      try {
        await this.formRef.validate();
        const ini = currentRoute.value.path.split("/")[1];
        const res = await API.forgotPssword(this.formState, ini);

        const { status, message = "" } = res.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) replace({path: `/${ini}/login`});
      } catch (error) {
        if (error?.message) {
          Notification.show(error?.message, {type: "error", title: "Error"});
        }
      }
    }
  },
};
</script>
